import { graphql, useStaticQuery } from 'gatsby'

export const getCaseStudyTestemonials = (path: string) => {
  const caseStudies = useStaticQuery(
    CASE_STUDY_TESTEMONIALS,
  )?.allContentfulCaseStudyTestemonial?.nodes

  return caseStudies?.filter(
    (caseStudy: { path: string }) =>
      caseStudy.path === path,
  )
}

export const CASE_STUDY_TESTEMONIALS = graphql`
  query CaseStudyTestemonials {
    allContentfulCaseStudyTestemonial {
      nodes {
        path
        fullName
        designation
        review {
          review
        }
        profilePicture {
          gatsbyImage(width: 100, quality: 80)
        }
        # embeddedVideo {
        #   childMarkdownRemark {
        #     html
        #   }
        # }
      }
    }
  }
`
