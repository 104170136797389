import React, { useState } from 'react'
import { BannerNormalizer } from 'sections/ContactUs/styled-components'
import { Helmet } from 'react-helmet'
import {
  CarouselContainer,
  IntroduceSection,
  LayoutFooter,
  ReviewContainer,
  StyledSection,
} from './styled-components'
import {
  ModalTestimonial,
  StyledButton,
  StyledText,
} from 'components/common'
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faPlay,
} from '@fortawesome/free-solid-svg-icons'
import { UsersReviewCarousel } from 'components/carousels'
import { GlobalStyle } from 'layouts'
import { CaseStudyProps } from 'interfaces/PageInterfaces'
import { GatsbyImage } from 'gatsby-plugin-image'
import defineHtmlAst from 'helpers/defineHtmlAst'
import { getCaseStudyTestemonials } from 'helpers/getCaseStudyTestemonials'

const Layout = ({
  pageContext,
}: {
  pageContext: { node: CaseStudyProps }
}) => {
  const [activeReview, setActiveReview] = useState(-1)
  const caseStudy = pageContext.node
  const caseStudyTestemonials = getCaseStudyTestemonials(
    caseStudy.path,
  )

  console.log(caseStudyTestemonials)

  const images = useStaticQuery(query)
  if (caseStudy.companyAchivements)
    caseStudy.companyAchivementsMapped = defineHtmlAst(
      caseStudy.companyAchivements,
    )

  const companyLogo = caseStudy?.companyLogo?.gatsbyImage

  const svgs = images?.allSvg.nodes

  return (
    <>
      <Helmet title={caseStudy?.title} />
      <BannerNormalizer />
      <IntroduceSection>
        <div className="heading">
          <h6>{caseStudy?.title}</h6>
          <div className="bottom-line"></div>
        </div>
        <StyledText textAlign="center">
          {caseStudy?.header}
        </StyledText>
        <div className="summary">
          <div className="logo-container">
            <GatsbyImage
              image={companyLogo}
              alt="company logo"
            />
          </div>
          <div className="about">
            <h6>About {caseStudy?.company}</h6>
            <p>{caseStudy?.about?.about}</p>
          </div>
          <div className="achivements">
            {caseStudy?.companyAchivementsMapped?.map(
              (label, index: number) => {
                return (
                  <div key={index}>
                    <p key={index}>- {label}</p>
                  </div>
                )
              },
            )}
          </div>
        </div>
      </IntroduceSection>
      {caseStudy.situationSectionTitle && (
        <StyledSection
          backgroundColor={'rgba(255,255,255,1)'}
        >
          <div className="heading">
            {svgs.find(
              ({
                relativePath,
              }: {
                relativePath: string
              }) => relativePath === 'images/svg/book.svg',
            )?.publicURL && (
              <div className="icon-container">
                <img
                  src={
                    svgs.find(
                      ({
                        relativePath,
                      }: {
                        relativePath: string
                      }) =>
                        relativePath ===
                        'images/svg/book.svg',
                    )?.publicURL
                  }
                  alt="section icon"
                />
              </div>
            )}
            <h6 className="title">
              {caseStudy.situationSectionTitle}
            </h6>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html:
                caseStudy.situationSectionDescription
                  .childMarkdownRemark.html,
            }}
          />
        </StyledSection>
      )}
      {caseStudy.taskSectionTitle && (
        <StyledSection
          backgroundColor={'rgba(6, 198, 143, 0.04)'}
        >
          <div className="heading">
            <h6 className="title">
              {caseStudy.taskSectionTitle}
            </h6>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html:
                caseStudy.taskSectionDescription
                  .childMarkdownRemark.html,
            }}
          />
        </StyledSection>
      )}
      {caseStudy.actionSectionTitle && (
        <StyledSection
          backgroundColor={'rgba(255,255,255,1)'}
        >
          <div className="heading">
            {svgs.find(
              ({
                relativePath,
              }: {
                relativePath: string
              }) => relativePath === 'images/svg/power.svg',
            )?.publicURL && (
              <div className="icon-container">
                <img
                  src={
                    svgs.find(
                      ({
                        relativePath,
                      }: {
                        relativePath: string
                      }) =>
                        relativePath ===
                        'images/svg/power.svg',
                    )?.publicURL
                  }
                  alt="section icon"
                />
              </div>
            )}
            <h6 className="title">
              {caseStudy.actionSectionTitle}
            </h6>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html:
                caseStudy.actionSectionDescription
                  .childMarkdownRemark.html,
            }}
          />
        </StyledSection>
      )}
      {caseStudy.resultSectionTitle && (
        <StyledSection
          backgroundColor={'rgba(6, 198, 143, 0.04)'}
        >
          <div className="heading">
            <h6 className="title">
              {caseStudy.resultSectionTitle}
            </h6>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html:
                caseStudy.resultSectionDescription
                  .childMarkdownRemark.html,
            }}
          />
          {caseStudy.resultSectionMedia && (
            <StyledButton
              color="#06C68F"
              to={caseStudy.resultSectionMedia}
            >
              Watch Video{' '}
              <FontAwesomeIcon
                icon={faPlay}
                className="button-icon"
              />
            </StyledButton>
          )}
        </StyledSection>
      )}
      {caseStudyTestemonials.length > 0 && (
        <>
          <GlobalStyle isNavOpen={activeReview !== -1} />
          <ModalTestimonial
            {...caseStudyTestemonials[activeReview]}
            onClose={() => setActiveReview(-1)}
          />
          <CarouselContainer>
            <UsersReviewCarousel
              reviews={caseStudyTestemonials}
              setActiveReview={setActiveReview}
              activeReview={activeReview}
            />
          </CarouselContainer>
        </>
      )}
      {/* {caseStudy?.body.map(
        (
          {
            sectionName,
            sectionMedia,
            sectionBullets,
            sectionContent,
            sectionFooter,
            sectionBgColor,
            sectionIcon,
          }: SectionProps,
          index: number,
        ) => {
          switch (sectionName) {
            case 'carousel':
              return (
                <>
                  <GlobalStyle
                    isNavOpen={activeReview !== -1}
                  />
                  <ModalTestimonial
                    {...sectionContent[activeReview]}
                    profilePic={
                      imageNodes.find(
                        ({
                          relativePath,
                        }: {
                          relativePath: string
                        }) =>
                          relativePath ===
                          sectionContent[activeReview]
                            ?.profilePic,
                      )?.childImageSharp.fluid
                    }
                    onClose={() => setActiveReview(-1)}
                  />
                  <CarouselContainer>
                    <UsersReviewCarousel
                      reviews={sectionContent.map(
                        (review: any) => {
                          const staticImageData =
                            imageNodes.find(
                              ({
                                relativePath,
                              }: {
                                relativePath: string
                              }) =>
                                relativePath ===
                                review.profilePic,
                            )
                          return {
                            ...review,
                            profilePic:
                              staticImageData
                                ?.childImageSharp.fluid,
                          }
                        },
                      )}
                      setActiveReview={setActiveReview}
                      activeReview={activeReview}
                    />
                  </CarouselContainer>
                </>
              )
            case 'review':
              const staticImageData = imageNodes.find(
                ({
                  relativePath,
                }: {
                  relativePath: string
                }) =>
                  relativePath ===
                  sectionContent.profilePic,
              )
              const review = {
                className: 'review',
                ...sectionContent,
                profilePic:
                  staticImageData?.childImageSharp.fluid,
              }
              return (
                <ReviewContainer>
                  <CardReview {...review} />
                </ReviewContainer>
              )
            default:
              return (
                <StyledSection
                  key={index}
                  backgroundColor={sectionBgColor}
                >
                  <div className="heading">
                    {svgs.find(
                      ({
                        relativePath,
                      }: {
                        relativePath: string
                      }) => relativePath === sectionIcon,
                    )?.publicURL && (
                      <div className="icon-container">
                        <img
                          src={
                            svgs.find(
                              ({
                                relativePath,
                              }: {
                                relativePath: string
                              }) =>
                                relativePath ===
                                sectionIcon,
                            )?.publicURL
                          }
                          alt="section icon"
                        />
                      </div>
                    )}
                    <h6 className="title">{sectionName}</h6>
                  </div>
                  <div className="content">
                    <p className="sectionContent">
                      {sectionContent}
                    </p>
                    <ul className="sectionBullets">
                      {sectionBullets?.map(
                        (bullet: string, index: number) => {
                          return (
                            <li key={index}>
                              <div className="green-circle"></div>
                              <p>{bullet}</p>
                            </li>
                          )
                        },
                      )}
                    </ul>
                    <p className="sectionFooter">
                      {sectionFooter}
                    </p>
                    {sectionMedia && (
                      <StyledButton
                        color="#06C68F"
                        to={sectionMedia}
                      >
                        Watch Video{' '}
                        <FontAwesomeIcon
                          icon={faPlay}
                          className="button-icon"
                        />
                      </StyledButton>
                    )}
                  </div>
                </StyledSection>
              )
          }
        },
      )} */}
      <LayoutFooter>
        <StyledText>Want to know more?</StyledText>
        <StyledButton
          color="white"
          paddings={117}
          to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
        >
          Schedule a Consultation
          <FontAwesomeIcon
            icon={faArrowRight}
            className="button-icon"
          />
        </StyledButton>
      </LayoutFooter>
    </>
  )
}

export default Layout

// using different subqueris to get images and svgs
// because childImageSharp can't optimize svgs and returns undefined
const query = graphql`
  query Images {
    allFile: allFile(
      filter: {
        relativePath: { regex: "/^images/" }
        extension: { ne: "svg" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
        extension
        publicURL
      }
    }
    allSvg: allFile(
      filter: {
        relativePath: { regex: "/^images/" }
        extension: { eq: "svg" }
      }
    ) {
      nodes {
        relativePath
        publicURL
      }
    }
  }
`
