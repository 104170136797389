import styled from 'styled-components'

export const IntroduceSection = styled.section`
  padding: 56px 0px 64px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 36px 0px 54px 0;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 20px 0px 44px 0;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 0;
  }

  .title {
    padding: 0 165px;
    @media (max-width: ${({ theme }) =>
        theme.breakpoints.wl}) {
      padding: 0 100px;
    }
    @media (max-width: ${({ theme }) =>
        theme.breakpoints.wm}) {
      padding: 0 50px;
    }
    @media (max-width: ${({ theme }) =>
        theme.breakpoints.wxs}) {
      padding: 20px;
    }
  }

  .heading {
    h6 {
      color: #8636cc;
      font-size: 16px;
      padding-bottom: 16px;
      font-family: 'Poppins', sans-serif;
    }
    .bottom-line {
      width: 100%;
      height: 5px;
      border-radius: 24px;
      background-color: #8636cc;
    }
  }

  .title {
    margin: 24px 0 48px 0;
    text-align: center;
  }

  .summary {
    display: flex;

    .logo-container {
      flex: 1.7;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 53px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      border-radius: 2px;

      .gatsby-image-wrapper {
        width: 35%;
      }

      @media (max-width: ${({ theme }) =>
          theme.breakpoints.wl}) {
        flex: 1.5;
      }
      @media (max-width: ${({ theme }) =>
          theme.breakpoints.wm}) {
        flex: 1.4;

        .gatsby-image-wrapper {
          width: 50%;
        }
      }
    }

    .about {
      background-color: rgba(6, 198, 143, 0.04);
      padding: 30px;
      flex: 1;
      font-family: 'Poppins', sans-serif;

      h6 {
        font-size: 16px;
        margin-bottom: 16px;
      }
      p {
        font-family: 'Poppins-ExtraLight', sans-serif;
        font-size: 20px;
        color: #4c4c4c;
      }
    }

    .achivements {
      flex: 1.7;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 42px;
      padding: 36px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      border-radius: 2px;

      @media (max-width: ${({ theme }) =>
          theme.breakpoints.wl}) {
        flex: 1.5;
      }

      @media (max-width: ${({ theme }) =>
          theme.breakpoints.wm}) {
        flex: 1.4;
      }

      div {
        display: flex;
        align-items: center;
        gap: 24px;

        .icon {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          color: #4c4c4c;
          font-family: 'Poppins-ExtraLight', sans-serif;
          font-size: 1.3rem;
          //   letter-spacing: 0.8px;
        }
      }
    }

    @media (max-width: ${({ theme }) =>
        theme.breakpoints.ws}) {
      flex-direction: column;

      .logo-container {
        justify-content: center;
      }
    }
  }
`

export const StyledSection = styled.section<{
  backgroundColor: string
}>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? '#fff'};
  padding: 56px 25% 64px 25%;

  .heading {
    margin-bottom: 16px;
    .icon-container {
      width: 65px;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e6f9f4;
      border-radius: 4px;
      margin-bottom: 24px;
    }

    .title {
      font-family: 'Poppins-Bold', sans-serif;
      font-size: 24px;
    }
  }

  .content {
    font-family: 'Poppins-light', sans-serif;
    font-size: 18px;
    color: #4c4c4c;
    line-height: 30px;
    margin-bottom: 10px;
    p {
      white-space: break-spaces;
    }

    &:first-child {
      margin-bottom: 16px;
      // white-space: pre-line;
    }
    ul {
      margin-block: 20px;
      margin-left: 14px;
      li {
        display: flex;
        margin-block: 10px;
        align-items: flex-start;
        gap: 8px;
        &:before {
          content: '';
          poisition: absolute;
          left: 0;
          transform: translateX(-100%);
          border-radius: 50%;
          width: 10px;
          margin-top: 7px;
          height: 10px;
          aspect-ratio: 1;
          border: 2px solid #06c68f;
        }
      }
    }
    &::last-child {
      margin-top: 16px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.ws}) {
    padding: 56px 20px;
  }
`

export const CarouselContainer = styled.div`
  padding: 110px 165px;
  ${({ theme }) => theme.placeholders.maxWidth}

  @media (max-width: ${({ theme }) =>
    theme.breakpoints.wl}) {
    padding: 70px 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 70px 50px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 70px 20px;
  }
`

export const LayoutFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 120px;

  .title {
    margin-bottom: 32px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding-inline: 20px;
    .link {
      width: 100%;
      .custom-button {
        width: 100%;
        justify-content: center;
        padding-inline: 0;
      }
    }
  }
`

export const ReviewContainer = styled.div`
  padding: 85px 165px 0px 165px;
  ${({ theme }) => theme.placeholders.maxWidth}
  @media (max-width: ${({ theme }) =>
    theme.breakpoints.wl}) {
    padding: 64px 100px 0px 100px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 64px 50px 0px 50px;
  }
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 64px 20px 0px 20px;
  }

  .review {
    height: auto !important;
  }
`
