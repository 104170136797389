const defineHtmlAst = (data: any): string[] =>
  data.childrenMarkdownRemark[0].htmlAst.children
    .find(
      (child: { tagName: string }) =>
        child.tagName === 'ul',
    )
    .children.filter(
      (article: { tagName: string }) =>
        article.tagName === 'li',
    )
    .map((article: { children: { type: string }[] }) =>
      article.children.map(
        (child: any) =>
          child.type === 'text' && child.value,
      ),
    )
    .map((article: string[]) => article[0])

export default defineHtmlAst

export const isValidElement = (element: any) => {
  return element !== undefined && element !== '!'
}
